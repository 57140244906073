import React from 'react'

export default function H1({ title }) {

  return (
    <>
    <div className="container-fluid mb-3 mb-md-8">
      <h1 className="col-md-6" data-scroll="" data-splitting="">
        {title}
      </h1>
    </div>
    </>
  )
}