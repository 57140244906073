import React, {useEffect} from 'react';
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";


import Seo from "../components/Seo";
import Splitting from "splitting";
import ScrollOut from "scroll-out";

import H1 from '../components/H1';
 
// markup
const IndexPage = ({data}) => {
  const {
    wpPage: { title, content, seo, angebot, featuredImage },
  } = data

  useEffect(() => {
    // do something
    Splitting();
    const scroll = ScrollOut();

    setTimeout(() => {
      scroll.update();
    },300);

    return () => {
      scroll.teardown();
    }
  }, []);

  return (
    <>
    <Seo title={seo.title} description={seo.metaDesc} />
      <H1 title={title } />
      
      <div className="container-fluid mb-10 mb-md-40">

      { featuredImage && 
            <GatsbyImage  image={getImage(featuredImage.node.localFile)} alt="Fint Digital Design" className="mb-5 mb-md-10" />
          }

        <div  data-scroll="out"  className="col-md-6 offset-md-6 col-xl-5 fade-in">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
   
   
      <div className="container-fluid ">

        <div className="row">
          <div className="col-md-6">
            <h2 className="h1 mb-8 mb-md-20">Unsere Kompetenzen</h2>
          </div>
          <div className="col-md-6">
            {angebot.kompetenzen?.map((el)=> (
              <div className="row mb-10 md-md-20">  
                <div className="col-md-5">
                   <h3 dangerouslySetInnerHTML={{ __html: el.titel }} className="h2 mb-5 mb-md-10" />
                 </div>
                <div className="col-md-7 mb-3 mb-md-0">
                  <div dangerouslySetInnerHTML={{ __html: el.text }} />
                 </div>
              </div>
            ))}
          </div>
        </div>


        <div className="row mb-10">
          <div className="col-md-6">
            <h2 className="h1 mb-8 mb-md-20">Im Detail</h2>
          </div>

          <div className="col-md-6">
            <div className="row">

                <div className="col-md-5">
                  <h4 className="h2 small">Websites</h4>
                </div>
                  <div className="col-md-7 mb-3 mb-md-0">
                    <ul>
                      <li>Corporate Websites</li>
                      <li>Microsites</li>
                      <li>Landing­pages</li>
                      <li>Webshop &amp; E-Commerce</li>
                      <li>Mehrsprachigkeit</li>
                      <li>Seo-Optimierungen</li>
                      <li>Performance-Optimierungen</li>
                    </ul>
                  </div>
             <div className="row ">
     

              <div className="col-md-5">
                <h4 className="h2 small">Web-Apps</h4>
                </div>
                  <div className="col-md-7 mb-3 mb-md-0">
                <ul>
                  <li>Custom-Made Tools, zB zur Datenerfassung / Auswertung</li>
                  <li>Austellungs-Screens / Terminals</li>
                </ul>
              </div>
              </div>

              <div className="row mb-10 md-md-20">

              <div className="col-md-5">
                <h4 className="h2">Technologien</h4>
              </div>
              <div className="col-md-7 mb-3 mb-md-0">
                <ul>
                  <li>WordPress</li>
                  <li>React</li>
                  <li>Gatsby JS</li>
                  <li>Next.js</li>
                </ul>
              </div>
              </div>

            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 offset-md-6 h1">
          Bereit mit uns zu arbeiten? Kontaktieren Sie uns, um zu besprechen, wie wir Ihnen beim Aufbau einer starken digitalen Markenpräsenz helfen können.
          </div>
        </div>
       </div>
    </>
  )
}


export const query = graphql`
{
  wpPage(databaseId: {eq: 2678}) {
    title
    content
    featuredImage {
      node {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    seo {
      title
      metaDesc
    }
    angebot {
      kompetenzen {
        text
        titel
      }
    }
  }
}
`

export default IndexPage
